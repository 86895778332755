export const CRYPTOCURRENCY_FORM = [
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin BTC",
    memo: false,
    reserve: 272,
    min: 0.0002,
    max: 13.581978,
    label: "Bitcoin address",
    wallet: "bc1qf2qx8m0pvpurg7cqm0563ew7w4nlr8kdlh9f6j",
    icon: require("../assets/img/cryptocurrency/btc.png"),
  },
  {
    id: "bitcoincash",
    symbol: "BCH",
    name: "Bitcoin Cash BCH",
    memo: false,
    reserve: 551619,
    min: 29.34745924,
    max: 1320.63566597,
    label: "Bitcoin Cash address",
    wallet: "qzwmgaglar5jpn963p5rhcz8y3tnsml995n52are23",
    icon: require("../assets/img/cryptocurrency/bch.png"),
  },
  {
    id: "ethereum",
    symbol: "ETH",
    name: "Ether ETH",
    memo: false,
    reserve: 0,
    min: 29.34745924,
    max: 1320.63566597,
    label: "Ethereum address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/etc.png"),
  },
  {
    id: "ethereumclassic",
    symbol: "ETC",
    name: "Ether Classic ETC",
    memo: false,
    reserve: 174048,
    min: 612.296756,
    max: 27553.354009,
    label: "Ethereum Classic address",
    wallet: "0x8E87Cd57385e78365849095A2e6C9e76De6F036e",
    icon: require("../assets/img/cryptocurrency/etc.png"),
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "Litecoin LTC",
    memo: false,
    reserve: 138387,
    min: 193.38619,
    max: 8702.37865,
    label: "Litecoin address",
    wallet: "ltc1qlrqfwualxc0u0nq4d5j4yu7eswj9e67fzwpf37",
    icon: require("../assets/img/cryptocurrency/ltc.png"),
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero XMR",
    memo: false,
    reserve: 20024630,
    min: 0,
    max: 0,
    label: "Monero address",
    wallet: "0x8E87Cd57385e78365849095A2e6C9e76De6F036e",
    icon: require("../assets/img/cryptocurrency/xmr.png"),
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon MATIC",
    memo: false,
    reserve: 99202686,
    min: 22275.66,
    max: 1002404.66,
    label: "Polygon address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/matic.png"),
  },
  {
    id: "dogecoin",
    symbol: "DOGE",
    name: "Dogecoin DOGE",
    memo: false,
    reserve: 0,
    min: 110048,
    max: 4952178,
    label: "Dogecoin address",
    wallet: "D9JToHcsHwEJrd6Aa1Z128xtQ8vkLy9jk3",
    icon: require("../assets/img/cryptocurrency/doge.png"),
  },
  {
    id: "dash",
    symbol: "DASH",
    name: "DASH",
    memo: false,
    reserve: 646784,
    min: 543.77379,
    max: 24469.82055,
    label: "Dash address",
    wallet: "Xyan53aXytJd829tZ5nfzpZzbAFCzSND7y",
    icon: require("../assets/img/cryptocurrency/dash.png"),
  },
  {
    id: "zcach",
    symbol: "ZEC",
    name: "Zcach ZEC",
    memo: false,
    reserve: 18000000,
    min: 719.16577,
    max: 32362.45955,
    label: "Zcach address",
    wallet: "t1NpQr8VD7ch3ZztDGfuWJGTVXBp7oBDgho",
    icon: require("../assets/img/cryptocurrency/zec.png"),
  },
  {
    id: "tethererc",
    symbol: "USDC1",
    name: "Tether ERC20 USDT",
    memo: false,
    reserve: 0,
    min: 10,
    max: 900000,
    label: "Tether ERC20 address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/erc20.png"),
  },
  {
    id: "tethertrc",
    symbol: "USDC2",
    name: "Tether TRC20",
    memo: false,
    reserve: 397350993,
    min: 10,
    max: 900000,
    label: "Tether TRC20 address",
    wallet: "TB7VxFGtMvVCGnSx4jVMwqp2EdZMZ4rsbF",
    icon: require("../assets/img/cryptocurrency/trc20.png"),
  },
  {
    id: "nem",
    symbol: "XEM",
    name: "NEM XEM",
    memo: false,
    reserve: 18287108,
    min: 443458.980044,
    max: 19955654.101996,
    label: "NEM address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/xem.png"),
  },
  {
    id: "neo",
    symbol: "NEO",
    name: "NEO",
    memo: false,
    reserve: 0,
    min: 1366.12,
    max: 61475.41,
    label: "NEO address",
    wallet: "",
    icon: require("../assets/img/cryptocurrency/neo.png"),
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS",
    memo: false,
    reserve: 30943732,
    min: 20412.329047,
    max: 918554.807103,
    label: "EOS address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/eos.png"),
  },
  {
    id: "cardano",
    symbol: "ADA",
    name: "Cardano ADA",
    memo: false,
    reserve: 153072939,
    min: 34520.076,
    max: 1553403.4206,
    label: "Cardano address",
    wallet:
      "addr1q8jmcc8et5t735dj78ypf3a990eqy7uylkkwmx2qx8wanrgsd6r4584ast28sd0un4smkj458r7xwtes4hx827fkpqnq4g0fx6",
    icon: require("../assets/img/cryptocurrency/ada.png"),
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "Tron TRX",
    memo: false,
    reserve: 57822037,
    min: 170251.89,
    max: 7661334.95,
    label: "Tron address",
    wallet: "TB7VxFGtMvVCGnSx4jVMwqp2EdZMZ4rsbF",
    icon: require("../assets/img/cryptocurrency/trx.png"),
  },
  {
    id: "omg",
    symbol: "OMG",
    name: "OMG Network OMG",
    memo: false,
    reserve: 0,
    min: 20964.360587,
    max: 943396.226415,
    label: "OMG Network address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/omg.png"),
  },
  {
    id: "zrx",
    symbol: "ZRX",
    name: "0x Protocol ZRX",
    memo: false,
    reserve: 0,
    min: 30492.4531,
    max: 1372160.3903,
    label: "0x Protocol address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/zrx.png"),
  },
  {
    id: "iconicx",
    symbol: "ICX",
    name: "ICON ICX",
    memo: false,
    reserve: 56550424,
    min: 64453.7544,
    max: 2900418.94943,
    label: "ICON ICX address",
    wallet: "hx415630ad4ed145f520c03a5eb982beca3326642f",
    icon: require("../assets/img/cryptocurrency/icx.png"),
  },
  {
    id: "bat",
    symbol: "BAT",
    name: "BAT",
    memo: false,
    reserve: 0,
    min: 68752.1485,
    max: 3093846.6827,
    label: "BAT address",
    wallet: "",
    icon: require("../assets/img/cryptocurrency/bat.png"),
  },
  {
    id: "ontology",
    symbol: "ONT",
    name: "Ontology ONT",
    memo: false,
    reserve: 4233601,
    min: 62932.6621,
    max: 2831969.7923,
    label: "Ontology address",
    wallet: "AaY5sU42ggZYr8dpY7nDzYaGu5C7yMqH2q",
    icon: require("../assets/img/cryptocurrency/ont.png"),
  },
  {
    id: "qtum",
    symbol: "QTUM",
    name: "Qtum",
    memo: false,
    reserve: 1620045.3613,
    min: 4724.4466,
    max: 212600.0992,
    label: "Qtum address",
    wallet: "QLgkqeupPbCJRasj8uiMuvsZSxFXaPikZy",
    icon: require("../assets/img/cryptocurrency/qtum.png"),
  },
  {
    id: "rub",
    symbol: "RUB",
    name: "Карта RUB",
    memo: false,
    reserve: 56620045.3613,
    min: 10000,
    max: 300000,
    label: "Карта RUB",
    wallet: "Ожидайте письмо на указанный Вами E-mail",
    icon: require("../assets/img/cryptocurrency/ru.png"),
  },
  {
    id: "link",
    symbol: "LINK",
    name: "Chainlink LINK",
    memo: false,
    reserve: 0,
    min: 1118.7059,
    max: 50341.7646,
    label: "Chainlink address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/link.png"),
  },
  {
    id: "atom",
    symbol: "ATOM",
    name: "Cosmos ATOM",
    memo: false,
    reserve: 2121115.7069,
    min: 1804.6958,
    max: 81211.3118,
    label: "Cosmos address",
    wallet: "cosmos1wpf3wqkjsxr3u8yzzen79w8hcej2e07v8kprn7",
    icon: require("../assets/img/cryptocurrency/atom.png"),
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot DOT",
    memo: false,
    reserve: 14436959,
    min: 2362.4745,
    max: 106311.3505,
    label: "Polkadot address",
    wallet: "154zdS1rcvmtxTJFbEVp37NqnjLrRieQLzSX2QvD2kqciJ2n",
    icon: require("../assets/img/cryptocurrency/dot.png"),
  },
  {
    id: "tezos",
    symbol: "XTZ",
    name: "Tezos XTZ",
    memo: false,
    reserve: 432328570,
    min: 16119.0229,
    max: 725356.0289,
    label: "Tezos address",
    wallet: "tz1bjRSWoWDhdja92u3FtEKZvBBcZgG3uSAa",
    icon: require("../assets/img/cryptocurrency/xtz.png"),
  },
  {
    id: "uniswap",
    symbol: "UNI",
    name: "Uniswap UNI",
    memo: false,
    reserve: 0,
    min: 1802.6463,
    max: 81119.0828,
    label: "Uniswap address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/uni.png"),
  },
  {
    id: "solana",
    symbol: "SOL",
    name: "Solana SOL",
    memo: false,
    reserve: 0,
    min: 108.1261,
    max: 4865.6749,
    label: "Solana address",
    wallet: "2vM72J7EVnWnfKZ3Ga6XoXhESB9UhYc5Cj5SQhtRcBvy",
    icon: require("../assets/img/cryptocurrency/sol.png"),
  },
  {
    id: "vecgain",
    symbol: "VET",
    name: "VeChain VET",
    memo: false,
    reserve: 75630252.1008,
    min: 482136.8304,
    max: 21696157.3695,
    label: "VeChain address",
    wallet: "0xc8D6De733EC10b7edCE5bf3F67382A300DD9b944",
    icon: require("../assets/img/cryptocurrency/vet.png"),
  },
  {
    id: "shiba",
    symbol: "SHIB",
    name: "Shiba Inu SHIB REC20",
    memo: false,
    reserve: 0,
    min: 746268657,
    max: 33582089552,
    label: "Shiba Inu address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/shib.png"),
  },
  {
    id: "algorand",
    symbol: "ALGO",
    name: "Algorand ALGO",
    memo: false,
    reserve: 381134,
    min: 84245.9983,
    max: 3791069.9242,
    label: "Algorand address",
    wallet: "OI7YBWFSKKDQXTZR6TAFWHYMSKYAFZFUP4RNHPHKC4B73Z5C5JWQSNH3JA",
    icon: require("../assets/img/cryptocurrency/algo.png"),
  },
  {
    id: "maker",
    symbol: "MKR",
    name: "Maker MKR",
    memo: false,
    reserve: 0,
    min: 5.2477,
    max: 236.148,
    label: "Maker address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/mkr.svg").default,
  },
  {
    id: "avalanche",
    symbol: "AVAX",
    name: "Avalanche AVAX",
    memo: false,
    reserve: 2166,
    min: 425.3256,
    max: 19139.6514,
    label: "Avalanche address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/avax.svg").default,
  },
  {
    id: "yearn",
    symbol: "YFI",
    name: "Yearn.finance YFI",
    memo: false,
    reserve: 30000,
    min: 2.411,
    max: 108.4957,
    label: "Yearn.finance address",
    wallet: "",
    icon: require("../assets/img/cryptocurrency/yfi.svg").default,
  },
  {
    id: "decentraland",
    symbol: "MANA",
    name: "Decentraland MANA",
    memo: false,
    reserve: 0,
    min: 34083.1629,
    max: 1533742.3313,
    label: "Decentraland address",
    wallet: "0x26E58bd6DAe8140a62b5efBC671D083f8eF9aEdd",
    icon: require("../assets/img/cryptocurrency/mana.svg").default,
  },
  {
    id: "near",
    symbol: "NEAR",
    name: "NEAR Protocol",
    memo: false,
    reserve: 10000,
    min: 2978.85,
    max: 134048.26,
    label: "NEAR Protocol address",
    wallet: "a6ccd4ad24212f08c43998198e8a1e66e386d0f605258d25d783924b3396af4b",
    icon: require("../assets/img/cryptocurrency/near.svg").default,
  },
  {
    id: "cny",
    symbol: "CNYPAY",
    name: "Yuan CNY",
    memo: false,
    reserve: 10000,
    min: 500,
    max: 20000,
    label: "Yuan address",
    wallet: "a6ccd4ad24212f08c43998198e8a1e66e386d0f605258d25d783924b3396af4b",
    icon: require("../assets/img/cryptocurrency/cny.png"),
  },
];

export const CRYPTOCURRENCY_TO = [
  {
    id: "payonnerusd",
    symbol: "USDPAY",
    name: "Payoneer USD",
    memo: false,
    reserve: 10000,
    labelWallet: "Wallet",
    placeholder: "mypayonner@gmail.com",
    icon: require("../assets/img/cryptocurrency/payonner.png"),
  },
  {
    id: "payonnereur",
    symbol: "EURPAY",
    name: "Payoneer EUR",
    memo: false,
    reserve: 11000,
    labelWallet: "Wallet",
    placeholder: "mypayonner@gmail.com",
    icon: require("../assets/img/cryptocurrency/payonner.png"),
  },
  {
    id: "wiseusd",
    symbol: "USDWISE",
    name: "Wise USD",
    memo: false,
    reserve: 0,
    labelWallet: "Wallet",
    placeholder: "example@wise.com",
    icon: require("../assets/img/cryptocurrency/wise.svg").default,
  },
  {
    id: "wisegbp",
    symbol: "GBPWISE",
    name: "Wise GBP",
    memo: false,
    reserve: 1000,
    labelWallet: "Wallet",
    placeholder: "example@wise.com",
    icon: require("../assets/img/cryptocurrency/wisegbp.png"),
  },
  {
    id: "wiseeur",
    symbol: "EURWISE",
    name: "Wise EUR",
    memo: false,
    reserve: 0,
    labelWallet: "Wallet",
    placeholder: "example@wise.com",
    icon: require("../assets/img/cryptocurrency/wise.svg").default,
  },
  {
    id: "sepaeur",
    symbol: "EURSEPA",
    name: "Sepa EUR",
    memo: false,
    reserve: 1000,
    labelWallet: "Wallet",
    placeholder: "RS85583493729634831",
    icon: require("../assets/img/cryptocurrency/sepa.png"),
  },
  {
    id: "qtum",
    symbol: "RUB",
    name: "RUB",
    memo: false,
    reserve: 620045.3613,
    labelWallet: "Карта RUB",
    placeholder: "Карта RUB",
    icon: require("../assets/img/cryptocurrency/ru.png"),
  },
  {
    id: "bitcoin",
    symbol: "BTC",
    name: "Bitcoin BTC",
    memo: false,
    reserve: 272,
    labelWallet: "Bitcoin address",
    placeholder: "Bitcoin address",
    icon: require("../assets/img/cryptocurrency/btc.png"),
  },
  {
    id: "bitcoincash",
    symbol: "BCH",
    name: "Bitcoin Cash BCH",
    memo: false,
    reserve: 551619,
    labelWallet: "Bitcoin Cash address",
    placeholder: "Bitcoin Cash address",
    icon: require("../assets/img/cryptocurrency/bch.png"),
  },
  {
    id: "ethereumclassic",
    symbol: "ETC",
    name: "Ether Classic ETC",
    memo: false,
    reserve: 174048,
    labelWallet: "Ethereum Classic address",
    placeholder: "Ethereum Classic address",
    icon: require("../assets/img/cryptocurrency/etc.png"),
  },
  {
    id: "litecoin",
    symbol: "LTC",
    name: "Litecoin LTC",
    memo: false,
    reserve: 138387,
    labelWallet: "Litecoin address",
    placeholder: "Litecoin address",
    icon: require("../assets/img/cryptocurrency/ltc.png"),
  },
  {
    id: "monero",
    symbol: "XMR",
    name: "Monero XMR",
    memo: false,
    reserve: 20024630,

    placeholder: "Monero address",
    icon: require("../assets/img/cryptocurrency/xmr.png"),
  },
  {
    id: "polygon",
    symbol: "MATIC",
    name: "Polygon MATIC",
    memo: false,
    reserve: 99202686,
    labelWallet: "Polygon address",
    placeholder: "Polygon address",
    icon: require("../assets/img/cryptocurrency/matic.png"),
  },
  {
    id: "dogecoin",
    symbol: "DOGE",
    name: "Dogecoin DOGE",
    memo: false,
    reserve: 0,
    labelWallet: "Dogecoin address",
    placeholder: "Dogecoin address",
    icon: require("../assets/img/cryptocurrency/doge.png"),
  },
  {
    id: "dash",
    symbol: "DASH",
    name: "DASH",
    memo: false,
    reserve: 646784,
    labelWallet: "Dogecoin address",
    placeholder: "Dash address",
    icon: require("../assets/img/cryptocurrency/dash.png"),
  },
  {
    id: "zcach",
    symbol: "ZEC",
    name: "Zcach ZEC",
    memo: false,
    reserve: 18000000,
    labelWallet: "Dogecoin address",
    placeholder: "Zcach address",
    icon: require("../assets/img/cryptocurrency/zec.png"),
  },
  {
    id: "tethertrc",
    symbol: "USDC",
    name: "Tether TRC20",
    memo: false,
    reserve: 397350993,
    labelWallet: "Dogecoin address",
    placeholder: "Tether TRC20 address",
    icon: require("../assets/img/cryptocurrency/trc20.png"),
  },
  {
    id: "nem",
    symbol: "XEM",
    name: "NEM XEM",
    memo: {
      placeholder: "Memo, if required for deposit",
    },
    reserve: 18287108,
    labelWallet: "Dogecoin address",
    placeholder: "NEM address",
    icon: require("../assets/img/cryptocurrency/xem.png"),
  },
  {
    id: "eos",
    symbol: "EOS",
    name: "EOS",
    memo: {
      placeholder: "Memo, if required for deposit",
    },
    reserve: 30943732,
    labelWallet: "Dogecoin address",
    placeholder: "EOS address",
    icon: require("../assets/img/cryptocurrency/eos.png"),
  },
  {
    id: "cardano",
    symbol: "ADA",
    name: "Cardano ADA",
    memo: false,
    reserve: 153072939,
    labelWallet: "Dogecoin address",
    placeholder: "Cardano address",
    icon: require("../assets/img/cryptocurrency/ada.png"),
  },
  {
    id: "tron",
    symbol: "TRX",
    name: "Tron TRX",
    memo: false,
    reserve: 57822037,
    labelWallet: "Dogecoin address",
    placeholder: "Tron address",
    icon: require("../assets/img/cryptocurrency/trx.png"),
  },
  {
    id: "iconicx",
    symbol: "ICX",
    name: "ICON ICX",
    memo: false,
    reserve: 56550424,
    labelWallet: "Dogecoin address",
    placeholder: "ICON address",
    icon: require("../assets/img/cryptocurrency/icx.png"),
  },
  {
    id: "ontology",
    symbol: "ONT",
    name: "Ontology ONT",
    memo: false,
    reserve: 4233601,
    labelWallet: "Dogecoin address",
    placeholder: "Ontology address",
    icon: require("../assets/img/cryptocurrency/ont.png"),
  },
  {
    id: "atom",
    symbol: "ATOM",
    name: "Cosmos ATOM",
    memo: {
      placeholder: "Memo, if required for deposit",
    },
    reserve: 2121115.7069,
    labelWallet: "Dogecoin address",
    placeholder: "Cosmos address",
    icon: require("../assets/img/cryptocurrency/atom.png"),
  },
  {
    id: "polkadot",
    symbol: "DOT",
    name: "Polkadot DOT",
    memo: false,
    reserve: 14436959,
    labelWallet: "Dogecoin address",
    placeholder: "Polkadot address",
    icon: require("../assets/img/cryptocurrency/dot.png"),
  },
  {
    id: "tezos",
    symbol: "XTZ",
    name: "Tezos XTZ",
    memo: false,
    reserve: 432328570,
    labelWallet: "Dogecoin address",
    placeholder: "Tezos address",
    icon: require("../assets/img/cryptocurrency/xtz.png"),
  },
  {
    id: "vecgain",
    symbol: "VET",
    name: "VeChain VET",
    memo: false,
    reserve: 75630252.1008,
    labelWallet: "VeChain address",
    placeholder: "VeChain address",
    icon: require("../assets/img/cryptocurrency/vet.png"),
  },
  {
    id: "algorand",
    symbol: "ALGO",
    name: "Algorand ALGO",
    memo: false,
    reserve: 381134,
    labelWallet: "Algorand address",
    placeholder: "Algorand address",
    icon: require("../assets/img/cryptocurrency/algo.png"),
  },
  {
    id: "avalanche",
    symbol: "AVAX",
    name: "Avalanche AVAX",
    memo: false,
    reserve: 2166,
    labelWallet: "Avalanche address",
    placeholder: "Avalanche address",
    icon: require("../assets/img/cryptocurrency/avax.svg").default,
  },
  {
    id: "yearn",
    symbol: "YFI",
    name: "Yearn.finance YFI",
    memo: false,
    reserve: 30000,
    labelWallet: "Yearn.finance address",
    placeholder: "Yearn.finance address",
    icon: require("../assets/img/cryptocurrency/yfi.svg").default,
  },
  {
    id: "paypalusd",
    symbol: "USDPAL",
    name: "PayPal USD",
    memo: false,
    reserve: 30000,
    labelWallet: "Wallet",
    placeholder: "mypaypal@gmail.com",
    icon: require("../assets/img/cryptocurrency/paypal.svg").default,
  },
  {
    id: "near",
    symbol: "NEAR",
    name: "NEAR Protocol",
    memo: false,
    reserve: 10000,
    labelWallet: "NEAR Protocol address",
    placeholder: "NEAR Protocol address",
    icon: require("../assets/img/cryptocurrency/near.svg").default,
  },
  {
    id: "paypaleur",
    symbol: "EURPAL",
    name: "PayPal EUR",
    memo: false,
    reserve: 10000,
    labelWallet: "Wallet",
    placeholder: "mypaypal@gmail.com",
    icon: require("../assets/img/cryptocurrency/paypal.svg").default,
  },
  {
    id: "paypalgbp",
    symbol: "GBPPAL",
    name: "PayPal GBP",
    memo: false,
    reserve: 10000,
    labelWallet: "Wallet",
    placeholder: "mypaypal@gmail.com",
    icon: require("../assets/img/cryptocurrency/paypal.svg").default,
  },
  {
    id: "paypalaud",
    symbol: "AUDPAL",
    name: "PayPal AUD",
    memo: false,
    reserve: 10000,
    labelWallet: "Wallet",
    placeholder: "mypaypal@gmail.com",
    icon: require("../assets/img/cryptocurrency/paypal.svg").default,
  },
  {
    id: "paypalcad",
    symbol: "CADPAL",
    name: "PayPal CAD",
    memo: false,
    reserve: 10000,
    labelWallet: "Wallet",
    placeholder: "mypaypal@gmail.com",
    icon: require("../assets/img/cryptocurrency/paypal.svg").default,
  },
];
