import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import {
  CRYPTOCURRENCY_FORM,
  CRYPTOCURRENCY_TO,
} from "../../constants/CryptocurrencyArray";
import { IconAngleDown } from "../../utils/Icons";
import { formattedNumber } from "../../utils/formattedNumber";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function Exchange() {
  const { t } = useTranslation();
  const basePath = "exchange.";

  const getDefaultState = () => ({
    amount: null,
    currencyTo: "USDPAY",
    currencyFrom: "BTC",
    infoCoinTo: CRYPTOCURRENCY_TO[0],
    infoCoinFrom: CRYPTOCURRENCY_FORM[0],
    email: "",
    wallet: "",
    exchangeRate: parseFloat(localStorage.getItem("exchangeRate")) || null,
    result: localStorage.getItem("exchangeResult") || "",
    submitTime: null,
  });

  const [state, setState] = useState(() => {
    const storedState = localStorage.getItem("exchangeFormData");
    return storedState ? JSON.parse(storedState) : getDefaultState();
  });

  const [isChecked, setIsChecked] = useState(false);

  const isFiatCurrency = (currency) => {
    const fiatCurrencies = ["USD", "EUR", "GBP", "AUD", "CAD", "CNY", "RUB"];
    const currencySymbol = currency.substring(0, 3).toUpperCase();
    return fiatCurrencies.includes(currencySymbol);
  };

  const fetchRate = async (currency) => {
    const actualCurrency = getActualSymbol(currency);
    const url = isFiatCurrency(currency)
      ? `https://api.exchangerate-api.com/v4/latest/${actualCurrency}`
      : `https://api.binance.com/api/v3/ticker/price?symbol=${actualCurrency}USDT`;

    return fetch(url);
  };

  const getActualSymbol = (symbol) => {
    if (symbol && symbol.startsWith("USDC")) {
      return "USDT";
    }
    return symbol || "";
  };

  const fetchExchangeRate = async () => {
    try {
      let fromCurrencyToUsdRate;
      let toCurrencyToUsdRate;

      const fromResponse = await fetchRate(state.currencyFrom);
      const fromCurrencyData = await fromResponse.json();
      fromCurrencyToUsdRate = isFiatCurrency(state.currencyFrom)
        ? fromCurrencyData.rates.USD
        : parseFloat(fromCurrencyData.price);

      const toResponse = await fetchRate(state.currencyTo);
      const toCurrencyData = await toResponse.json();
      toCurrencyToUsdRate = isFiatCurrency(state.currencyTo)
        ? toCurrencyData.rates.USD
        : parseFloat(toCurrencyData.price);

      if (!fromCurrencyToUsdRate || !toCurrencyToUsdRate) {
        throw new Error("Failed to fetch exchange rates");
      }

      const exchangeRate =
        (state.currencyFrom === "TRX") & (state.currencyTo === "USDC")
          ? 0.248826
          : fromCurrencyToUsdRate / toCurrencyToUsdRate;

      // const exchangeRate = fromCurrencyToUsdRate / toCurrencyToUsdRate;

      setState((prevState) => ({
        ...prevState,
        exchangeRate,
      }));
      localStorage.setItem("exchangeRate", exchangeRate);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setState((prevState) => ({
        ...prevState,
        exchangeRate: null,
      }));
      localStorage.removeItem("exchangeRate");
    }
  };

  console.log(state.currencyTo);
  

  const calculateExchange = () => {
    const { amount, exchangeRate } = state;

    if (isNaN(amount) || amount <= 0 || exchangeRate === null) {
      setState((prevState) => ({
        ...prevState,
        result: null,
      }));
      return;
    }

    const calculatedResult = (amount * exchangeRate).toFixed(8);
    setState((prevState) => ({
      ...prevState,
      result: calculatedResult,
    }));
    localStorage.setItem("exchangeResult", calculatedResult);
  };

  useEffect(() => {
    fetchExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currencyFrom, state.currencyTo]);

  useEffect(() => {
    calculateExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amount, state.exchangeRate]);

  useEffect(() => {
    localStorage.setItem("exchangeFormData", JSON.stringify(state));
  }, [state]);

  const validateMessages = {
    required: "Введите сумму",
  };

  const onFinish = () => {
    const currentTime = new Date().getTime();
    setState((prevState) => ({
      ...prevState,
      submitTime: currentTime,
    }));
    console.log("Success");
  };

  return (
    <div className="container">
      <Row justify="center">
        <Col xl={20} lg={20} md={24} sm={24} xs={24} className="p-col">
          <div className="exchange">
            <Form validateMessages={validateMessages} onFinish={onFinish}>
              <div className="form">
                <div className="item-coin">
                  <Form.Item>
                    <Select
                      showSearch
                      suffixIcon={
                        <IconAngleDown width="34" height="34" fill="#899699" />
                      }
                      value={state.currencyFrom}
                      onChange={(value) => {
                        const selectedCurrency = CRYPTOCURRENCY_FORM.find(
                          (item) => item.symbol === value
                        );
                        setState((prevState) => ({
                          ...prevState,
                          currencyFrom: value,
                          infoCoinFrom: selectedCurrency,
                        }));
                      }}
                    >
                      {CRYPTOCURRENCY_FORM.map((item, index) => (
                        <Option value={item.symbol} key={index}>
                          <img src={item.icon} alt={item.name} />
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <InputNumber
                      placeholder={t(`${basePath}amount`)}
                      value={state.amount}
                      onChange={(value) =>
                        setState((prevState) => ({
                          ...prevState,
                          amount: value,
                        }))
                      }
                      controls={false}
                    />
                  </Form.Item>
                </div>

                <div className="item-coin">
                  <Form.Item>
                    <Select
                      showSearch
                      suffixIcon={
                        <IconAngleDown width="34" height="34" fill="#899699" />
                      }
                      value={state.currencyTo}
                      onChange={(value) => {
                        const selectedCurrency = CRYPTOCURRENCY_TO.find(
                          (item) => item.symbol === value
                        );
                        setState((prevState) => ({
                          ...prevState,
                          currencyTo: value,
                          infoCoinTo: selectedCurrency,
                        }));
                      }}
                    >
                      {CRYPTOCURRENCY_TO.map((item, index) => (
                        <Option value={item.symbol} key={index}>
                          <img src={item.icon} alt={item.name} />
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <InputNumber
                      id="result"
                      value={state.result}
                      disabled
                      controls={false}
                      placeholder={t(`${basePath}amount`)}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="info-coin">
                <div className="items">
                  <div className="item">
                    <span>{t(`${basePath}info.rate`)}</span>
                    <b>
                      {(state.currencyFrom === "TRX") &
                      (state.currencyTo === "USDC")
                        ? 0.1184
                        : formattedNumber(1 * state.exchangeRate).replace(
                            ",",
                            "."
                          )}{" "}
                      : 1
                    </b>
                  </div>
                  <div className="item">
                    <span>{t(`${basePath}info.min`)}</span>
                    <b>
                      {formattedNumber(state.infoCoinFrom.min, {
                        max: 20,
                      }).replace(",", ".")}{" "}
                      {getActualSymbol(state.infoCoinFrom.symbol)}
                    </b>
                  </div>
                </div>
                <div className="items">
                  <div className="item">
                    <span>{t(`${basePath}info.reserve`)}</span>
                    <b>
                      {formattedNumber(state.infoCoinTo.reserve).replace(
                        ",",
                        "."
                      )}{" "}
                      {state.infoCoinTo.labelWallet === "Wallet"
                        ? String(state.infoCoinTo.symbol).slice(0, 3)
                        : getActualSymbol(state.infoCoinTo.symbol)}
                    </b>
                  </div>
                  <div className="item">
                    <span>{t(`${basePath}info.max`)}</span>
                    <b>
                      {formattedNumber(state.infoCoinFrom.max, {
                        max: 20,
                      }).replace(",", ".")}{" "}
                      {getActualSymbol(state.infoCoinFrom.symbol)}
                    </b>
                  </div>
                </div>
              </div>

              <Row className="details">
                <Col xl={14} lg={14} md={14} sm={24} xs={24} className="pr-15">
                  <Form.Item name={"email"} type="email">
                    <Input
                      placeholder={"E-Mail"}
                      value={state.email}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                  <Form.Item name={"wallet"}>
                    <Input
                      placeholder={state.infoCoinTo.placeholder}
                      value={state.wallet}
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          wallet: e.target.value,
                        }))
                      }
                    />
                  </Form.Item>
                  {state.infoCoinTo.memo && (
                    <Form.Item name={"memo"}>
                      <Input
                        placeholder={state.infoCoinTo.memo.placeholder}
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            memo: e.target.value,
                          }))
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(console.log("Error")),
                      },
                    ]}
                  >
                    <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
                      {t(`${basePath}details.check.text`)}{" "}
                      <a href="/tеrms_of_service">
                        {t(`${basePath}details.check.rules`)}
                      </a>{" "}
                      {t(`${basePath}details.check.and`)}{" "}
                      <a href="/privacy_policy">
                        {t(`${basePath}details.check.aml`)}
                      </a>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xl={10} lg={10} md={10} sm={24} xs={24} className="pl-15">
                  <div className="card">
                    <div className="title">
                      {t(`${basePath}details.info.title`)}
                    </div>
                    <div className="text">
                      <p>{t(`${basePath}details.info.desc1`)}</p>
                      <p>{t(`${basePath}details.info.desc2`)}</p>
                      <p>{t(`${basePath}details.info.desc3`)}</p>
                      <p>{t(`${basePath}details.info.desc4`)}</p>
                      <p></p>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="d-flex">
                <Button
                  href="/personal/order/"
                  htmlType="submit"
                  className="btn-primary"
                  type="primary"
                  disabled={
                    !state.amount || !state.email || !state.wallet || !isChecked
                  }
                  onClick={onFinish}
                >
                  {t(`${basePath}btn`)}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Exchange;
